import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import { Layout, Bio, PostItem, SEO, RecipeInfo } from "@components"
import { rhythm } from "../utils/typography"
import { Flex, Text, Box } from "rebass"
import { CldImage } from "@extend"
import { capitalizeString } from "../utils/index"
import { optimizeImg } from "@utils"
import Ad from "../components/ads/Ad"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const posts = data.allMarkdownRemark.edges
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const siteUrl = data.site.siteMetadata.siteUrl
  const { previous, next } = pageContext
  const readingTime = post.timeToRead
  const info = post.frontmatter.info
  const language = pageContext.language
  const [postImage, setPostImage] = useState(null)

  useEffect(() => {
    setPostImage(optimizeImg('height', '100vh', post.frontmatter.image))    
  }, [post.frontmatter.image])

  return (
    <Layout location={location} title={siteTitle} posts={posts}>
      <SEO
        title={capitalizeString(post.frontmatter.title)}
      />
      <article>
        <header>
          <Text as="h1" className="uppercase capitalize main-title" mt={100} mb={30} letterSpacing={3} fontSize={50} textAlign={'center'}>{post.frontmatter.title}</Text>
          <Link color={'black'} to={`categories/${post.frontmatter.category}`}>
            <Text mb={30} className='uppercase category' textAlign={'center'} fontSize={18} color='black'>{post.frontmatter.category}</Text>
          </Link>
          <Box textAlign={['center', 'right']}>
            <Text as="p" className="uppercase" fontSize={14} color={'black'} letterSpacing={3}>Reading time: {readingTime === 1 ? '1 min' : `${readingTime} mins`}</Text>
            {
              language === 'es' ?
              <Link to={location.pathname.replace('/es', '')} className="uppercase language">Read it in english</Link>
              :
              <Link to={`${location.pathname}/es/`} className="uppercase language">Leer en español</Link>
            }
          </Box>
          {
            info ?
            <Box>
              <RecipeInfo info={info}/>
            </Box>
            :
            null
          }
          <Box
            height={'100vh'}
            className="post-image"
            sx={{
                backgroundImage: `url(${postImage})`,
                backgroundSize: 'cover',
                
                backgroundPosition: 'center',
            }}
          />
        </header>
        <Flex flexDirection={['column', 'row']} justifyContent='space-between'>
          <Box as="section"
          p={20}
          className="post"
          width={[1, .50]}
          dangerouslySetInnerHTML={{ __html: post.html }} />
          <Box width={[1, .50]} pl={[0, 20]}>
            {
              post.frontmatter.subimage ?
              <CldImage
              className="post-image"
              mt={20}
              height='500px'
              sx={{ objectFit: 'cover' }}
              alt={post.frontmatter.title}
              src={post.frontmatter.subimage}
              />
              :
              null
            }
            <Ad style={{height: '450px'}}/>
          </Box>
                   
        </Flex>
        {
          info && info.reference ? 
          <Text as="p" color="gray" fontSize="16px">Inspired by {info.reference}</Text>
          : null
        }
        <hr
          style={{
            marginTop: rhythm(.5),
            marginBottom: rhythm(.5),
          }}
        />
        <footer
          style={{
            marginBottom: rhythm(1),
          }}
        >
          <Bio
            postData={{
              author: post.frontmatter.author,
              date: post.frontmatter.date,
              title: post.frontmatter.title,
              path: `${siteUrl}${location.pathname}`,
            }}
          />
        </footer>
      </article>
      <Box as="nav"
        sx={{
        display: 'grid',
        gridGap: 1,
        gridTemplateColumns: 'repeat(3, minmax(128px, 1fr))',
        '@media screen and (max-width: 64em)': {
            gridTemplateColumns: 'repeat(1, minmax(128px, 1fr))',
        }
      }}>
        {
          previous && (
            <Box className="next-previous">
              <Text textAlign={'center'} letterSpacing={5}><Link to={previous.fields.slug} className="uppercase link">Previous</Link></Text>
              <PostItem post={previous} label="Previous"></PostItem>
            </Box>
          )
        }
        <Box/>
        {
          next && (
            <Box className="next-previous">
              <Text textAlign={'center'} letterSpacing={5}><Link to={next.fields.slug} className="uppercase link">Next</Link></Text>
              <PostItem post={next} label="Next"></PostItem>
            </Box>
          )
        }
      </Box>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: {fileAbsolutePath: {regex: "/index/"}}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            author
            category
            image
          }
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        author
        category
        image
        subimage
        info {
          serves
          preparation
          cooking
          reference
        }
      }
    }
  }
`
