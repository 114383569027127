import React from "react";
import { Flex } from "rebass"

const Ad = ({style}) => {
    const insertAd = () => {
        return {__html: 
        `
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
            <!-- Display Ads -->
            <ins class="adsbygoogle"
            style="display:block"
            data-ad-client="ca-pub-4651068788999536"
            data-ad-slot="7007239478"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
            <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
        `}
    }
    return (
        <Flex as="section" flexDirection={'column'} py={'20px'}>
        <div style={style} dangerouslySetInnerHTML={insertAd()}>
        </div>
        </Flex>
        );
}

export default Ad
